
type Nav = { text: string, href: string, subNav?: Nav[] }

interface Config {
  appName: string;
  appSubtitle: string;
  nav: Nav[];
  meta: {
    description: string;
  },
  links: {
    email: string;
    instagram: string;
    facebook: string;
  }
}

export enum SubNavHeaderIds {
  // services
  events = 'events',
  weddings = 'weddings',
  design = 'design',
  // rentals
  missDaisy = 'miss-daisy',
  aLaCarte = 'a-la-carte',
}

export const config:Config = {
  appName: 'Ivory & Rust',
  appSubtitle: 'Weddings | Events | Design',
  nav: [{
    text: 'Home',
    href: '/'
  }, {
    text: 'Services',
    href: '/services',
    subNav: [{
      text: 'Events',
      href: `/services#${SubNavHeaderIds.events}`
    }, {
      text: 'Weddings',
      href: `/services#${SubNavHeaderIds.weddings}`,
    }, {
      text: 'Design',
      href: `/services#${SubNavHeaderIds.design}`,
    }]
  }, {
    text: 'Rentals',
    href: '/rentals',
    subNav: [{
      text: 'Miss Daisy',
      href: `/rentals#${SubNavHeaderIds.missDaisy}`
    }, {
      text: 'A la Carte',
      href: `/rentals#${SubNavHeaderIds.aLaCarte}`,
    }]
  }, {
    text: 'Riverleigh Room',
    href: '/riverleigh-room',
  }, {
    text: 'About',
    href: '/about',
  }, {
    text: 'Gallery',
    href: '/gallery'
  }, {
    text: 'Contact',
    href: '/contact',
  }],
  meta: {
    description: 'Servicing the Mid-Ohio Valley including Marietta OH, Parkersburg WV, and more. Services include Weddings, Events, Popups, Design, Rentals and more'
  },
  links: {
    email: 'ivoryandrustevents@gmail.com',
    instagram: 'https://www.instagram.com/ivoryandrust/',
    facebook: 'https://facebook.com/ivoryandrust',
  }
};

export function getHeadTitle(subRoute=''): string {
  return `${config.appName}: ${subRoute ? subRoute : config.appSubtitle}`
}
