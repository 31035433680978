import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: inline-block;
`;

interface Props {
	fill?: string;
	size?: number;
	brand: 'instagram' | 'facebook'
}

export default function LogoIcon(props:Props) {
	const {
		fill = 'currentColor',
		size = 24,
		brand,
	} = props;

	if (brand === 'instagram') {
		const contentSize = size * 0.8;
		const padSize = size - contentSize;
		return <Container style={{width: contentSize, height: contentSize, padding: padSize}}>
			<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.56 29.56" fill={fill}>
				<title>Glyph</title>
				<path
					d="M14.77 7.18a7.59 7.59 0 1 0 7.59 7.59 7.59 7.59 0 0 0-7.59-7.59zm0 12.52a4.93 4.93 0 1 1 4.93-4.93 4.93 4.93 0 0 1-4.93 4.93z"/>
				<circle cx="22.66" cy="6.9" r="1.77"/>
				<path
					d="M28.78 5.1A7.56 7.56 0 0 0 24.46.78a10.85 10.85 0 0 0-3.59-.69C19.29.02 18.79 0 14.78 0s-4.52 0-6.09.09A10.85 10.85 0 0 0 5.1.78 7.56 7.56 0 0 0 .78 5.1a10.85 10.85 0 0 0-.69 3.59C.02 10.27 0 10.77 0 14.78s0 4.52.09 6.09a10.85 10.85 0 0 0 .69 3.59 7.56 7.56 0 0 0 4.32 4.32 10.85 10.85 0 0 0 3.59.74c1.58.07 2.08.09 6.09.09s4.52 0 6.09-.09a10.85 10.85 0 0 0 3.59-.69 7.56 7.56 0 0 0 4.32-4.32 10.85 10.85 0 0 0 .69-3.59c.07-1.58.09-2.08.09-6.09s0-4.52-.09-6.09a10.85 10.85 0 0 0-.69-3.64zm-2 15.65a8.18 8.18 0 0 1-.51 2.77 4.9 4.9 0 0 1-2.81 2.81 8.18 8.18 0 0 1-2.74.51c-1.56.07-2 .09-6 .09s-4.41 0-6-.09a8.18 8.18 0 0 1-2.74-.51 4.89 4.89 0 0 1-2.82-2.81 8.18 8.18 0 0 1-.51-2.74c-.07-1.56-.09-2-.09-6s0-4.41.09-6a8.18 8.18 0 0 1 .51-2.77A4.89 4.89 0 0 1 5.98 3.2a8.18 8.18 0 0 1 2.74-.51c1.56-.07 2-.09 6-.09s4.41 0 6 .09a8.18 8.18 0 0 1 2.74.51 4.9 4.9 0 0 1 2.81 2.81 8.18 8.18 0 0 1 .51 2.74c.07 1.56.09 2 .09 6s0 4.43-.07 6z"/>
			</svg>
		</Container>
	}
	if (brand == 'facebook') {
		const contentSize = size * 0.8;
		const padSize = size - contentSize;
		return (
	  	  <Container style={{width: contentSize, height: contentSize, padding: padSize}}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14222 14222"><circle cx="7111" cy="7112" r="7111" fill={fill} /><path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="white"/></svg>
  		  </Container>
		);
	}

	return null;
};
