import * as React from 'react';
import styled from 'styled-components';
import { mq, pageGutters } from '../common/styles';

const StyledPageContent = styled.div`
  padding: 16px ${pageGutters.mobileS}px;
  ${mq.mobileL} {
    padding: 16px ${pageGutters.mobileL}px;
  }
  ${mq.laptop} {
   padding: 24px ${pageGutters.laptop}px;
  }
`;

export default StyledPageContent;

