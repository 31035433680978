const mediaMinWidth = (size) => `@media (min-width: ${size}px)`;

/**
 * Full min-width media queries to use in style compilation
 * https://jsramblings.com/how-to-use-media-queries-with-styled-components/
 */
export const mq = {
  mobileS: mediaMinWidth(320),
  mobileM: mediaMinWidth(375),
  mobileL: mediaMinWidth(425),
  tablet: mediaMinWidth(768),
  laptop: mediaMinWidth(1024),
  laptopL: mediaMinWidth(1440),
  desktop: mediaMinWidth(2560)
};
/**
 * Google fonts we are pulling in
 * https://johnny.am/blog/n2-adding-google-fonts-to-nextjs-project
 * https://fonts.google.com/specimen/Nothing+You+Could+Do?category=Handwriting&selection.family=Montserrat:wght@300;400;500|Nothing+You+Could+Do&sidebar.open=true#pairings
 */
export const fonts = {
  montserrat: `'Montserrat', sans-serif`,
  nothingYouCouldDo: `'Nothing You Could Do', cursive`,
};
/**
 * Named colors that match our style, not semantic to the theme elements
 */
export const colors = {
  ivory: '#FFFFF7',
  rust: '#B7410E',
  rustlight: '#DBA086',
  gunmetal: '#2A3439',
  gunmetallight: '#556872',
  ivy: '#297a6f',
  ivylight: '#59c9bc'
};
/**
 * Common styles (linked to breakpoints) for how much gutter page content should have by default
 */
export const pageGutters = {
  mobileS: 16,
  mobileL : 24,
  laptop: 56,
};
