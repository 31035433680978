import * as React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import { config, getHeadTitle } from '../common/config';
import Masthead from './Masthead';
import {colors, fonts, mq } from '../common/styles';
import styled, { createGlobalStyle } from 'styled-components';
import PageContent from './PageContent';
import Logos from './Logos';
// make sure every page gets the gallery CSS
import '@browniebroke/gatsby-image-gallery/dist/style.css'
// @ts-ignore
import ogImageUrl from '../images/logo-square500x500.png';

type Props = {
    subPageTitle?: string;
    children: React.ReactNode;
}

const GlobalStyles = createGlobalStyle`
   body {
      font-size:14px;
      background-color: ${colors.ivory};
      padding: 0;
      line-height: 1.8;
      font-family: ${fonts.montserrat};
      max-width: 1400px;
      margin: 0 auto;
    }
    h1, h2, h3, h4 {
      font-family: ${fonts.nothingYouCouldDo};
    }
    h1 {
      font-weight: 700;
    }
    p {
      margin-bottom: 10px;
    }
    a, a:visited {
      color: ${colors.ivy}; 
      text-decoration: none;
      &:hover {      
        color: ${colors.ivylight}; 
      }
    }
    .hide-mobile {
      display: none;
      ${mq.laptop} {
        display: revert;
      }
    }
    .hide-laptop {
      ${mq.laptop} {
        display: none;
      } 
    }
`;
const StyledFooter = styled.footer`
  min-height: 44px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  > div {
    margin: 0 16px;
  }
  .copyright {
    font-size: 12px;
  }
  a {
    display: flex;
    align-items: center;
  } 
`;

function Layout(props:Props) {
  const { children, subPageTitle } = props;

  return (
    <>
    <Helmet>
        <title>{getHeadTitle(subPageTitle)}</title>
        <meta name='description' content={config.meta.description} />

        <meta property='og:title' content={getHeadTitle(subPageTitle)} />
        <meta property='og:description' content={config.meta.description} />
        <meta property='og:image' content={ogImageUrl} />
        <link rel="image_src" href={ogImageUrl} />

        <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&family=Nothing+You+Could+Do&display=swap" rel="stylesheet"/>
    </Helmet>
    <GlobalStyles />
    <Masthead />
    <main>
        {children}
    </main>
    <PageContent>
        <StyledFooter>
        <div>
            {config.nav.map((navItem) => (
                <Link key={navItem.href} to={navItem.href}>{navItem.text}</Link>
            ))}
        </div>
        <div>
            <a href={config.links.instagram} title='Ivory + Rust on Instagram'>
                <Logos brand='instagram'/>
                Instagram
            </a>
            <a href={config.links.facebook} title='Ivory + Rust on Facebook'>
                <Logos brand='facebook'/>
                Facebook
            </a>
        </div>
        <div style={{flex: 1}}/>
        <div className='copyright'>
            © {config.appName} {new Date().getFullYear()}
            <br/>
            <br/>
            <a href='https://www.mariettachamber.com/' title='Marietta Area Chamber of Commerce'>
                <StaticImage
                    src='../images/macc_logo.jpg'
                    className='logo'
                    layout='fixed'
                    placeholder='none'
                    width={120}
                    alt='Marietta Area Chamber of Commerce'
                />
            </a>
        </div>
        </StyledFooter>
    </PageContent>
    </>
  );
}

export default Layout;
