import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage,  } from "gatsby-plugin-image"
import styled from 'styled-components';
import { Twirl as Hamburger } from 'hamburger-react'
import { config } from '../common/config';
import {fonts, mq, colors } from '../common/styles';

const StyledMasthead = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
 
  header {
    text-align: center;
    color: ${colors.gunmetal};
    font-family: ${fonts.nothingYouCouldDo};
    position: relative;
    margin: 16px 0;
    display: flex;
    justify-content: center;
    .logo {
      width: 280px;
    }
    .ham {
      position: absolute;
      top: -16px;
      right: -4px;
      ${mq.laptop} {
        display: none;
      }
    }
  } 
  nav {
      a, a:visited {
        margin: 0 4px;
        padding: 2px;
        font-weight: 500;
        text-decoration: none;
        color: ${colors.gunmetal};
        transition: 0.15s ease;
        &:hover {
          color: ${colors.gunmetallight};
          text-decoration: underline;
        }
        &[aria-current="page"] {
          color: ${colors.rust};
        }
      }
  }
  .mobile-menu-position {
    position: relative;
  }
  nav.mobile {
    ${mq.laptop} {
      display: none;
    }
    position: absolute;
    z-index: 5;
    background: ${colors.ivory};
    width: 100vw;
    top: 0px;
    left: 0px;
    padding: 18px;
    a {
      display: block;
      font-size: 18px;
      font-weight: 600;
    }
  }
  nav.full {
    display: none;
    ${mq.laptop} {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    
    .navChildren {
      opacity: 0;
      transition: opacity 0.15s ease;
      display: flex;
      margin-top: 4px;
      z-index: 100;
      position: absolute;
      flex-direction: column;
      background: ${colors.ivory};
      box-shadow: 0px 2px 2px rgba(0, 0, 0, .2);
      padding: 8px;
      width: 120px;
      text-align: left;
      .subNavItem {
      	word-break: keep-all;
      }      
    }
    
    .navParent {
      position: relative;
      padding: 2px;
      &:hover {
        .navChildren {
          opacity: 1;
        }     
      }
    }
    .navItem {
    
    }

  }
  nav.mobile {
    border-bottom: 3px solid ${colors.rust};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .2);
    .subNavItem {
      margin-left: 24px;
      font-size: 0.925em;
    }
  }
`;




function Masthead() {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const closeMenu = () => setMenuIsOpen(false);

  console.log('render Masthead');

  return (
    <StyledMasthead>
      <div>
        <header>
            <Link to='/' title={`${config.appName}: ${config.appSubtitle}`}>
                <StaticImage
                    /* HACK fix using key
                        Gatsby was reverting to placeholder after the following sequence on mobile
                        Load page
                        open nav
                        click on another page
                        open nav
                        [!] Logo disappears
                     */
                    key={Math.random()}
                    src='../images/logo-horz.png'
                    className='logo'
                    layout='fixed'
                    placeholder='none'
                    quality={100}
                    width={280}
                    alt={`${config.appName}: ${config.appSubtitle}`}
                />
            </Link>
          <div className='ham' onClick={() => setMenuIsOpen(!menuIsOpen)}>
            <Hamburger toggled={menuIsOpen} duration={0.2} color={colors.rust} size={24}/>
          </div>
        </header>
      </div>
      <div className='mobile-menu-position'>
        {menuIsOpen && (
            <nav className='mobile'>
                {config.nav.map(({text, href, subNav}) => (
                    <React.Fragment key={text}>
                        <Link className='navItem' to={href}>{text}</Link>
                        {subNav && subNav.map(({text, href}) => (
                            <Link className='subNavItem' key={text} to={href} onClick={closeMenu}>{text}</Link>
                        ))}
                    </React.Fragment>
                ))}
            </nav>
        )}
      </div>
        <nav className='full'>
            {config.nav.map(({text, href, subNav}) => (
                <div className='navParent' key={text}>
                    <Link className='navItem' to={href}>{text}</Link>
                    {subNav && (
                        <div className='navChildren'>
                            {subNav.map(({href, text}) => (
                                <Link className='subNavItem' key={text} to={href}>{text}</Link>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </nav>
    </StyledMasthead>
  );
}

export default Masthead;
